@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
  margin-top: 60px;
  height: 50vh;

  @include breakpoint(xlarge) {
    height: 80vh;
  }

  .grid-x {
    height: 50vh;

    @include breakpoint(xlarge) {
      height: 80vh;
    }
  }

  picture {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
      position: absolute;
    }
  }


  .splide__slide {
    opacity: 1;
    transition: all 800ms ease-in-out;
    text-align: left;
  }

  .is-active {
    opacity: 1;
    transition: all 800ms ease-in-out;
  }

  .is-visible {
    opacity: 1;
    transition: all 800ms ease-in-out;
  }

  .heading-h1{
    letter-spacing:1.3px;
  }
  .heading-h2{
    letter-spacing:2.5px;
  }
}

.scroll span {
  margin-top: 32px;
  margin-bottom: 32px;
  display: block;

  p {
    animation-duration: 3s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }
}

@include breakpoint(medium down) {
  .block_hero_home {
    .heading-h1{
      line-height: 25px;
    }
  }

  .block_links {
    .item {
      margin: 5px;

      &:nth-child(1n+5) {
        //display: none;
      }
    }
  }
}


;@import "sass-embedded-legacy-load-done:172";