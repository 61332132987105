@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_hero_home.scss";

.scroll span {
    position: relative;

    &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.657' height='25.657' viewBox='0 0 25.657 25.657'%3E%3Cpath id='Path_1744' data-name='Path 1744' d='M0,14.142V0H14.142' transform='translate(12.828 22.828) rotate(-135)' fill='none' stroke='%23313a45' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'/%3E%3C/svg%3E%0A");
        width: 26px;
        height: 26px;
        margin: 1px auto auto;
        display: block;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-delay: 1s;
        animation-name: scrollDown;
    }

    @keyframes scrollDown {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(10px);
        }
    }
}
